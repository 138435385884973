@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');

@mixin easy($speed: .2s) {
  transition: all $speed linear;
  -webkit-transition: all $speed linear;
  -moz-transition: all $speed linear;
  -o-transition: all $speed linear;
}

@mixin placeholder($color: #000, $italic : normal) {
  &::-webkit-input-placeholder {
    color: $color;
    font-style: $italic;
    opacity: 1 !important;
    transition: opacity 0.3s;
  }
  &:-moz-placeholder {
    color: $color;
    font-style: $italic;
    opacity: 1 !important;
    transition: opacity 0.3s;
  }
  &::-moz-placeholder {
    color: $color;
    font-style: $italic;
    opacity: 1 !important;
    transition: opacity 0.3s;
  }
  &:-ms-input-placeholder {
    color: $color;
    font-style: $italic;
    opacity: 1 !important;
    transition: opacity 0.3s;
  }

  &:focus::-webkit-input-placeholder {
    opacity: 0 !important;
    transition: opacity 0.3s;
  }
  &:focus::-moz-placeholder {
    opacity: 0 !important;
    transition: opacity 0.3s;
  }
  &:focus:-moz-placeholder {
    opacity: 0 !important;
    transition: opacity 0.3s;
  }
  &:focus:-ms-input-placeholder {
    opacity: 0 !important;
    transition: opacity 0.3s;
  }
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@mixin scrollbarsInit() {
  @include scrollbars(4px, #c5caca, rgba(217, 222, 222, 0.3))
}

;


body {
  background: #F1F3F5;
  font-family: 'Inter', sans-serif;
}

.content {
  background-color: #F1F3F5;
  margin-top: 0;
}

.wrap {
  //max-width: 1920px;
  max-width: 1440px;
  min-width: 375px;
  margin: 0 auto;
  padding: 0 40px;
  @media (max-width: 1130px) {
    padding: 0 20px;
  }

}

header {
  background: #383C40;
  padding: 10px 0;

  .btn.mobile-menu-button {
    display: none;
    margin-right: 20px !important;

    i {
      display: block
    }

    i + i {
      display: none;
    }

    @media (max-width: 992px) {
      display: inline-block
    }

    &.open {
      i {
        display: none
      }

      i + i {
        display: block
      }
    }
  }

  .logo {
    background: url(../img/logo-head.png);
    background-size: contain;
    width: 71px;
    height: 28px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 40px;
  }

  .wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .main-menu-wrap {
    display: inline-block;
    @media (max-width: 992px) {
      display: none;
      position: fixed;
      left: 0;
      top: 60px;
      bottom: 0;
      right: 0;
      background: rgba(56, 60, 64, 0.38);
      &.open {
        display: block
      }
    }
  }

  .main-menu {
    display: inline-block;
    padding: 0;
    margin: 0;
    list-style: none;

    a {
      display: block;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-decoration: none;
      position: relative;

      span {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        display: block;
        width: 36px;
        height: 36px;

        i {
          position: absolute;
          top: 50%;
          right: 8px;
          transform: translateY(-50%);
          font-size: 12px;
        }
      }
    }

    & > li {
      display: inline-block;
      position: relative;

      &:hover > a {
        background: #303439;
      }

      & > a {
        color: #fff;
        padding: 9px 15px;
        border-radius: 8px;

        span {
          display: none
        }
      }

      &.submenu {
        &:hover {
          & > a {
            &:after {
              content: '';
              display: block;
              height: 40px;
              position: absolute;
              left: 0;
              right: 0;
              top: 30px;
              background: #303439;
            }
          }

          & > div {
            display: flex
          }
        }

        & > div {
          left: 0;
          top: 48px;
          background: #303439;
          box-shadow: 0px 4px 24px rgba(4, 25, 46, 0.12);
          border-radius: 0px 0px 8px 8px;
          overflow: hidden;
          position: absolute;
          display: none;

          .level-3 {
            width: 233px;
            background: #FFFFFF;
            border-radius: 0px 0px 8px 0px;
            max-height: 600px;
            overflow-x: hidden;
            overflow-y: auto;

            ul[data-level="3"] {
              & > li {
                a {
                  color: #18191C;

                  &.hover {
                    color: #D00209;
                    background: #F1F3F5;
                  }

                  &:hover {
                    background: #F1F3F5
                  }

                  &.active {
                    color: #D00209;
                    background: #F1F3F5 !important;
                  }
                }
              }
            }
          }
        }

        ul[data-level="2"] {
          min-width: 233px;
          max-height: 600px;
          overflow-x: hidden;
          overflow-y: auto;

          & > li {
            a {
              color: #fff;
            }

            &.hover a {
              background: #D00209 !important;
            }

            & > .active {
              background: #D00209 !important;
            }
          }

          ul[data-level="3"] {
            display: none
          }
        }
      }
    }

    .submenu {
      ul {
        list-style: none;
        display: block;
        padding: 0;
        margin: 0;

        & > li a {
          padding: 9px 30px 9px 16px;
          white-space: nowrap;

          span {
            display: flex;
            align-items: center;

            svg {
              font-size: 0.7rem;
            }
          }
        }
      }
    }

    @media (max-width: 992px) {
      display: block;
      background: #303439;
      width: 33.3%;
      height: 100%;
      & > li {
        display: block;

        a {
          border-radius: 0;

          span {
            display: block;
          }

          &:after {
            display: none !important;
          }
        }

        &.hover {
          & > a {
            background: #272A2E;
          }

          & > div {
            display: block !important;
            overflow: visible !important;
          }
        }

        &.submenu {
          & > div {
            width: 33.3%;
            position: fixed;
            left: 33.3%;
            top: 60px;
            bottom: 0;
            box-shadow: none;
            background: #272A2E;
            border-radius: 0;

            .level-3 {
              width: 100%;
              height: 100%;
              position: absolute;
              max-height: 100%;
              left: 100%;
              top: 0;
              border-radius: 0;
            }
          }

          ul[data-level="2"] {
            width: 100%;
            min-height: 100%;
            min-width: 0;
          }
        }
      }
    }
  }

  .main-menu-mobile {
    overflow: hidden;
    width: 100%;
    top: 56px;

    .box {
      white-space: nowrap;
      background: #303439;
      height: 100%;
      @include easy(200ms);

      &.open {
        transform: translateX(-100%)
      }

      & > div {
        width: 100vw;
        display: inline-block;
        vertical-align: top;
        height: 100%;
        padding: 10px 20px;
        background: #303439;

        ul {
          display: block;
          padding: 0;
          margin: 0;

          li {
            display: block;

            a {
              display: block;
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;
              color: #E5E9ED;
              position: relative;
              text-decoration: none;
              padding: 12px 0;

              span i {
                position: absolute;
                top: 50%;
                right: 8px;
                transform: translateY(-50%);
                font-size: 12px;
              }
            }
          }
        }

        &.first-layer ul li a {
          font-size: 18px
        }

        &.last-layer {
          overflow-x: hidden;
          overflow-y: auto;

          & > div {
            display: none;
          }

          .back {
            font-weight: 400;
            font-size: 20px;
            line-height: 1;
            color: #FFFFFF;
            padding: 6px 20px 16px;
            border-bottom: 1px solid rgba(87, 92, 97, 0.2);
            margin: 0 -20px;

            i {
              vertical-align: top;
              font-size: 16px;
              opacity: 0.87;
              position: relative;
              top: 1px;
            }
          }

          .submenu {
            & > a span i {
              transform: translateY(-50%) rotate(90deg);
            }

            ul {
              @include easy(200ms);
              background: #272A2E;
              border-radius: 12px;
              padding: 0 32px;
              max-height: 0;

            }

            &.open {
              & > a span i {
                transform: translateY(-50%) rotate(-90deg);
              }

              ul {
                padding: 12px 32px;
                max-height: 10000px;
              }
            }
          }
        }
      }
    }
  }

  .action-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .btn-box {
      display: inline-flex;
      border-radius: 8px;
      background: #18191C;
      padding: 6px 0;

      .btn {
        width: 16px;
        padding: 5px;
        border-radius: 4px;
        height: 16px;
        margin: 0 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 20px;
          height: 20px;

        }
      }

      .activeBtn {
        border-color: #fff;
        background-color: #fff;
        color: #D00209
      }

      & + .btn.add-action {
        display: flex;
        align-items: center;
        width: auto;
        font-weight: 600;
        border-radius: 8px;
        text-transform: unset;
        font-size: 14px;
        line-height: 18px;
        padding: 13px 16px;

        i {
          position: static;
          transform: none;
          vertical-align: middle;
        }
      }
    }
  }

  .btn {
    display: inline-block;
    border-radius: 4px;
    background: #18191C;
    color: #fff;
    font-size: 20px;
    width: 36px;
    height: 36px;
    position: relative;
    margin: 0 4px;
    vertical-align: middle;
    cursor: pointer;
    @include easy(200ms);

    &.add-action {
      background: #D00209;
      border-color: #D00209;
      margin: 0 38px;
    }

    &.notify {
      background: #18191C;
      border-radius: 8px;
      padding: 0;
      color: #fff;

      span {
        width: 20px;
        height: 20px;
        background: #D00209;
        font-weight: 500;
        font-size: 10px;
        text-align: center;
        line-height: 20px;
        border-radius: 50%;
        position: absolute;
        top: -5px;
        right: -6px;
        color: #fff;
        border: 1px solid #383C40;
      }
    }

    &:hover {
      background: #575C61;
      border-color: #575C61;

      &.add-action {
        background: #f20209;
        border-color: #f20209;
      }
    }
  }

  &.mobile-head {
    display: none;

    .btn {
      margin: 0 12px 0 0
    }
  }

  @media (max-width: 769px) {
    display: none;
    &.mobile-head {
      display: block;
    }
  }

  .user-dropdown {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-left: 12px;

    .top {
      display: flex;
      align-items: center;
      cursor: pointer;

      .avatar {
        border: 1px solid #FFFFFF;
        border-radius: 8px;
        width: 34px;
        height: 34px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        display: inline-block;
      }

      .name {
        font-weight: 400;
        font-size: 14px;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        padding: 0 0 0 8px;

        i {
          vertical-align: middle;
          margin-left: 5px;
        }
      }
    }

    .down {
      background: #FFFFFF;
      box-shadow: 0px 2px 10px rgba(56, 60, 64, 0.08);
      border-radius: 8px;
      position: absolute;
      right: 0;
      top: calc(100% + 20px);
      min-width: 120px;
      padding: 5px 0;
      display: none;

      ul {
        padding: 0;
        margin: 0;
        display: block;

        li {
          display: block;
          padding: 6px 16px;

          a {
            display: block;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            color: #18191C;
            text-decoration: none;
          }

          &:hover {
            color: #D00209;
            background: #F1F3F5;

            a {
              color: #D00209;
            }
          }
        }
      }
    }

    .top.open + .down {
      display: block
    }

    @media (max-width: 1200px) {
      .top .name {
        display: none
      }
    }
    @media (max-width: 769px) {
      margin-left: 0;
    }
  }
}

.form-group {
  position: relative;
  margin-top: 0;
  margin-bottom: 20px;


  label {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #18191C;
    display: block;
    margin-bottom: 4px;
  }

  .form-control {
    font-family: 'Inter', sans-serif;
    width: 100%;
    height: 40px;
    box-shadow: none;
    background: #FFFFFF;
    border: none;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #18191C;
    margin: 0;
    padding: 11px 10px;
    box-sizing: border-box;

    &:hover {
      background-color: #fff;
      box-shadow: 0px 4px 8px rgba(32, 45, 58, 0.04);
    }

    &:focus, &:active {
      color: #18191C;
      box-shadow: 0 0 0 2px #E5E9ED;
      outline: none;
    }

    @include placeholder(#848C94);

    &.is-invalid {

    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &.disabled .form-control {
    background: #D6D6D6;
  }

  select.form-control {
    appearance: none;
    -webkit-appearance: none;
    background-repeat: no-repeat;
    background-size: 10px 6px;
    background-position: right 20px top 50%;
  }

  textarea {
    min-height: 80px;
    padding-top: 14px;
    resize: none;
  }

  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
}

.btn {
  border-radius: 8px;
  padding: 4px;
  border: 0;
  cursor: pointer;
  line-height: 1;
  font-weight: 600;
  font-size: 14px;
  z-index: 1;
  @include easy(200ms);

  &-red {
    height: 40px;
    background: #D00209;
    color: #fff;

    &:hover {
      background: #B70006
    }

    &:disabled {
      opacity: 0.3;
      cursor: default;
    }
  }

  .ico {
    font-size: 130%;
    vertical-align: middle;
    transform: scale(1.2);

    &-right {
      margin-left: 5px;
    }

    &-left {
      margin-right: 5px;
    }
  }

  &.wide {
    width: 100%
  }
}

.divider-10 {
  height: 10px;
}

.divider-12 {
  height: 12px;
}

.divider-15 {
  height: 15px;
}

.divider-20 {
  height: 20px;
}

.divider-30 {
  height: 30px;
}


.login-page {
  background: #18191C;
  overflow: hidden;
  position: relative;
  min-height: 100vh;

  .line {
    height: 2px;
    background: #383C40;
    position: absolute;
    transform: rotate(53deg);
    transform-origin: 0 0;
    z-index: 0;

    &.line-1 {
      width: 233px;
      top: 0;
      left: 60px;
    }

    &.line-2 {
      top: 0;
      left: 198px;
      min-width: 100vw;
    }

    &.line-3 {
      width: 233px;
      bottom: 0;
      right: 60px;
      transform-origin: 100% 100%;
    }

    @media (max-width: 992px) {
      &.line-1 {
        left: -80px;
        width: 300px
      }
      &.line-2 {
        display: none
      }
      &.line-3 {
        min-width: 150vh;
        bottom: 120px;
        right: 0
      }
    }
    @media (max-width: 767px) {
      &.line-1 {
        left: -128px;
      }
      &.line-3 {
        bottom: 240px;
      }
    }
  }

  .wrap-login {
    width: 440px;
    text-align: center;
    margin: 0 auto;
    padding-top: 52px;
    @include scrollbarsInit();
    overflow-y: auto;
    max-height: 100vh;
    zoom: 1.1;

    .logo {
      background: url(../img/logo-login.png);
      background-size: contain;
      width: 240px;
      height: 100px;
      display: block;
      margin: 0 auto 52px;
    }

    .box {
      background: #383C40;
      border-radius: 12px;
      padding: 40px 50px 50px;
      text-align: left;

      .title {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 32px;
      }

      .subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #848C94;
        margin: 0 26px 32px 26px;
      }

      .form-group {
        label {
          color: #fff
        }
      }

      .forgot-link {
        text-align: right;
        margin-top: -8px;
        margin-bottom: 32px;

        a {
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #FFFFFF;
          text-decoration: none;
        }
      }

      p {
        text-align: center;
        max-width: 80%;
        margin: 0 auto 32px;
        color: #848C94;
        margin-top: -22px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
      }
    }

    @media (max-width: 992px) {
      width: 478px;
    }
    @media (max-width: 767px) {
      width: 335px;
      padding-top: 40px;
      .logo {
        width: 120px;
        height: 50px;
        margin: 0 auto 40px;
      }
      .box {
        padding: 36px 24px;

        p {
          max-width: 100%;
        }
      }
    }
  }
}

.mobileMenuBox {
  position: absolute;
  z-index: 10;
  background: #303439;
  top: 53px;
  right: 0;
  left: 0;
  font-size: 20px;
  width: 100%;
  height: 100%;
  color: #E5E9ED;

  li {
    cursor: pointer;
  }

  .first-layer {
    span {
      font-size: 18px;
    }

    & svg {
      color: #E5E9ED;
      width: 15px;
      height: 15px;
    }
  }

  .last-layer {
    font-size: 20px;

    .back {
      padding: 16px;
      border-bottom: 1px solid #575C61;;
    }

    span {
      font-size: 16px;
    }

    & svg {
      color: #E5E9ED;
      width: 20px;
      height: 20px;
    }
  }

  .collapsedList {
    background: #272A2E;
    margin: 0 18px;
    border-radius: 12px;
  }

  .MuiListItemIcon-root {
    justify-content: end;
  }

  & svg {
    color: #E5E9ED;
  }
}