@mixin easy($speed: .2s) {
  transition: all $speed linear;
  -webkit-transition: all $speed linear;
  -moz-transition: all $speed linear;
  -o-transition: all $speed linear;
}

@mixin placeholder($color: #000, $italic : normal) {
  &::-webkit-input-placeholder {
    color: $color;
    font-style: $italic;
    opacity: 1 !important;
    padding-left: 11px;
    transition: opacity 0.3s;
  }
  &:-moz-placeholder {
    color: $color;
    font-style: $italic;
    opacity: 1 !important;
    transition: opacity 0.3s;
  }
  &::-moz-placeholder {
    color: $color;
    font-style: $italic;
    opacity: 1 !important;
    transition: opacity 0.3s;
  }
  &:-ms-input-placeholder {
    color: $color;
    font-style: $italic;
    opacity: 1 !important;
    transition: opacity 0.3s;
  }

  &:focus::-webkit-input-placeholder {
    opacity: 0 !important;
    transition: opacity 0.3s;
  }
  &:focus::-moz-placeholder {
    opacity: 0 !important;
    transition: opacity 0.3s;
  }
  &:focus:-moz-placeholder {
    opacity: 0 !important;
    transition: opacity 0.3s;
  }
  &:focus:-ms-input-placeholder {
    opacity: 0 !important;
    transition: opacity 0.3s;
  }
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@mixin scrollbarsInit() {
  @include scrollbars(4px, #c5caca, rgba(217, 222, 222, 0.3))
}

;


body.app {
  background: #F1F3F5;
  font-family: 'Inter', sans-serif;
}

h1 {
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  margin: 0 0 0
}

h2 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin: 0 0 0
}

h3 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  margin: 0 0 0
}

.body1-b {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600
}

.body1-r {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400
}

.body2-b {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600
}

.body2-r {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400
}

.body3-b {
  font-size: 12px;
  line-height: 14px;
  font-weight: 600
}

.body3-r {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400
}

.caption {
  font-size: 10px;
  line-height: 12px;
  font-weight: 400
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-16 {
  margin-bottom: 16px;
}

.fs-12 {
  font-size: 12px;
}

.text-gray-3 {
  color: #848C94
}

.text-red {
  color: #D00209
}

.wrap {
  //max-width: 1920px;
  max-width: 1440px;
  min-width: 375px;
  margin: 0 auto;
  padding: 32px 40px 0 40px;
  @media (max-width: 1130px) {
    padding: 0 20px;
  }

}


.cursor-pointer {
  cursor: pointer
}

.form-group {
  position: relative;
  margin-top: 0;


  .wrap-input {
    position: relative
  }

  label {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #18191C;
    display: block;
    margin-bottom: 4px;
    padding-left: 11px;
  }

  .right-ico {
    transform: translateY(-50%);
    position: absolute;
    right: 13px;
    top: 50%;
    color: #C9CED4;

    &.active {
      color: #D00209;
    }
  }

  label + .form-control + .right-ico {
    transform: translateY(calc(-50% + 8px))
  }

  .form-control {
    font-family: 'Inter', sans-serif;
    width: 100%;
    box-shadow: none;
    background: #FFFFFF;
    border: none;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #18191C;
    height: 40px;
    justify-content: center;

    svg {
      width: 20px;
      height: 20px;
    }

    &:hover {
      background-color: #fff;
      box-shadow: 0px 4px 8px rgba(32, 45, 58, 0.04);
    }

    &:focus, &:active {
      color: #18191C;
      box-shadow: 0 0 0 2px #E5E9ED;
      outline: none;
    }

    @include placeholder(#848C94);

    &.is-invalid {
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &.disabled .form-control {
    background: #D6D6D6;
  }

  select.form-control {
    appearance: none;
    -webkit-appearance: none;
    background-repeat: no-repeat;
    background-size: 10px 7px;
    padding-left: 11px;
    background-position: right 20px top 50%;
  }

  textarea {
    min-height: 80px;
    padding-top: 14px;
    resize: none;
  }

  &.phone-mask {
    .code {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #18191C;
      border-right: 1px solid #E5E9ED;
      padding: 11px 12px;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
}

.btn {
  border-radius: 8px !important;
  padding: 11px 24px !important;
  border: 1px solid #fff !important;
  cursor: pointer;
  line-height: 1;
  font-weight: 600;
  font-size: 14px;
  appearance: none;
  -webkit-appearance: none;
  @include easy(200ms);

  &.with-shadow {
    box-shadow: 0px 4px 8px rgba(6, 123, 252, 0.04);
  }

  &-default {
    height: 40px;
    background: #fff !important;
    color: #18191C;

    .ico {
      color: #D00209
    }

    &:hover {
      background: #fff;
      border: 1px solid #D00209 !important;

      .ico {
        color: #fff
      }
    }

    &:disabled {
      opacity: 0.3;
      cursor: default;
    }
  }

  &-red {
    height: 40px;
    background: #D00209;
    color: #fff;

    &:hover {
      background: #B70006
    }

    &:disabled {
      opacity: 0.3;
      cursor: default;
    }
  }

  &-dark {
    height: 40px;
    background: #383C40;
    color: #fff;

    &:hover {
      background: #B70006
    }

    &:disabled {
      opacity: 0.3;
      cursor: default;
    }
  }

  &-link {
    height: auto;
    background: transparent;
    color: #D00209;
    padding: 2px 3px;
    box-shadow: none;

    &:hover {
      color: #000
    }

    &:disabled {
      opacity: 0.3;
      cursor: default;
    }
  }

  &-ico {
    background: #FAFBFC;
    color: #848C94;
    padding: 5px 3px;

    &:hover {
      color: #fff;
      background: #848C94;
    }
  }

  .ico {
    font-size: 130%;
    vertical-align: middle;
    transform: scale(1.2) translateY(-1px);

    &-right {
      margin-left: 5px;
    }

    &-left {
      margin-right: 5px;
    }
  }

  &.wide {
    width: 100%
  }

  @media (max-width: 992px) {
    padding: 11px 18px;
  }
}

.dropMenu {
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(32, 45, 58, 0.1);
  transform: translateY(5px);
  overflow: hidden;
  @include easy(200ms);
  max-width: 300px;
  min-width: 100%;

  .dropitem {
    padding: 8px 20px;
    display: block;
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;

    .ico {
      vertical-align: middle;
      margin-right: 5px;
      transform: translateY(-1px);
      font-size: 18px;
      padding: 0;
    }

    &:hover {
      background: none;
      cursor: pointer;
      color: #D00209;
    }
  }

  &.right-view {
    right: 0
  }
}

.dropdown-custom {
  position: relative;
  text-align: left;

  &.open {
    .btn-toggle, .btn-default {
      color: black;

      &:hover {
        color: #ffffff;
      }
    }
  }
}


.divider-10 {
  height: 10px;
}

.divider-12 {
  height: 12px;
}

.divider-15 {
  height: 15px;
}

.divider-20 {
  height: 20px;
}

.divider-30 {
  height: 30px;
}


body.login-page {
  background: #18191C;
  overflow: hidden;
  position: relative;
  min-height: 100vh;

  .line {
    height: 2px;
    background: #383C40;
    position: absolute;
    transform: rotate(53deg);
    transform-origin: 0 0;
    z-index: -1;

    &.line-1 {
      width: 233px;
      top: 0;
      left: 60px;
    }

    &.line-2 {
      top: 0;
      left: 198px;
      min-width: 100vw;
    }

    &.line-3 {
      width: 233px;
      bottom: 0;
      right: 60px;
      transform-origin: 100% 100%;
    }

    @media (max-width: 992px) {
      &.line-1 {
        left: -80px;
        width: 300px
      }
      &.line-2 {
        display: none
      }
      &.line-3 {
        min-width: 150vh;
        bottom: 120px;
        right: 0
      }
    }
    @media (max-width: 767px) {
      &.line-1 {
        left: -128px;
      }
      &.line-3 {
        bottom: 240px;
      }
    }
  }

  .wrap-login {
    width: 440px;
    text-align: center;
    margin: 0 auto;
    padding-top: 52px;
    @include scrollbarsInit();
    overflow-y: auto;
    max-height: 100vh;

    .logo {
      background: url(../img/logo-login.png);
      background-size: contain;
      width: 240px;
      height: 100px;
      display: block;
      margin: 0 auto 52px;
    }

    .box {
      background: #383C40;
      border-radius: 12px;
      padding: 40px 50px 50px;
      text-align: left;

      .title {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 32px;
      }

      .form-group {
        label {
          color: #fff
        }
      }

      .forgot-link {
        text-align: right;
        margin-top: -8px;
        margin-bottom: 32px;

        a {
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #FFFFFF;
          text-decoration: none;
        }
      }

      p {
        text-align: center;
        max-width: 80%;
        margin: 0 auto 32px;
        color: #848C94;
        margin-top: -22px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
      }
    }

    @media (max-width: 992px) {
      width: 478px;
    }
    @media (max-width: 767px) {
      width: 335px;
      padding-top: 40px;
      .logo {
        width: 120px;
        height: 50px;
        margin: 0 auto 40px;
      }
      .box {
        padding: 36px 24px;

        p {
          max-width: 100%;
        }
      }
    }
  }
}

.filter-page {
  text-align: right;
  margin-bottom: 32px;

  .btn-open-filter.active {
    box-shadow: 0 0 0 1px #D00209
  }

  & > div {
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;

    &:last-child {
      margin-right: 0
    }
  }

  @media (max-width: 992px) {
    .form-group {
      width: 200px;
    }
  }
  @media (max-width: 769px) {
    text-align: left;
    margin-bottom: 24px;
    & > div {
      margin-right: 0;
      width: 33.3%;

      &:first-child {
        display: block;
        width: 100%;
      }

      &:nth-child(3) {
        padding-left: 8px;
        padding-right: 8px;
      }

      .form-group {
        width: 100%;
        margin-bottom: 12px;
      }

      .btn {
        width: 100%;
        font-size: 12px;
        padding: 14px 8px;
      }
    }
  }
}

.order-details {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 24px 32px 0;
  margin-bottom: 32px;

  .top, .down {
    div {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #575C61;
    }
  }

  .top {
    margin-bottom: 24px;
    display: flex;
    gap: 15px;

    & > div {
      flex: 1 1 calc(100% / 5);
    }

    .count {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #D00209;
      margin-bottom: 4px;
    }

    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #18191C;
      margin-bottom: 20px;
    }

    @media (max-width: 992px) {
      .count {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 4px;
      }
      .title {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 20px;
      }
    }
    @media (max-width: 769px) {
      .count {
        font-size: 12px;
        line-height: 14px;
      }
      .title {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 12px;
      }
    }
  }

  .down {
    border-top: 1px solid #E5E9ED;
    margin: 0 -32px;

    .body {
      min-height: 250px;
      overflow: hidden;
      display: flex;
      gap: 15px;
      padding: 24px 32px;

      & > div {
        flex: 1 1 calc(100% / 5);
      }

      .item {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        small {
          display: block;
          color: #848C94;
        }
      }
    }
  }

  .show-less {
    text-align: center;
    padding: 5px 0 7px;
    margin: 0 -32px;
    border-top: 1px solid #E5E9ED;

    & > div {
      display: inline-block;
      cursor: pointer;
      font-weight: 400;
      font-size: 12px;
      line-height: 1;
      color: #D00209;
    }

    span, i {
      vertical-align: baseline;
      display: inline-block;
      line-height: 1;
    }

    i {
      font-size: 127%;
      vertical-align: middle;
      margin-left: 5px;
    }

    span + span {
      display: none
    }

    i + i {
      display: none
    }
  }

  &.open {
    .down {
      .body {
        @include easy(200ms);
        max-height: 10000px;
        padding: 24px 32px;
        overflow: visible;
      }
    }

    .show-less {
      border-top: 1px solid #E5E9ED;

      span, i {
        display: none
      }

      span + span {
        display: inline-block
      }

      i + i {
        display: inline-block
      }
    }
  }

  @media (max-width: 769px) {
    padding: 16px 0 0;
    position: relative;
    margin-bottom: 24px;
    .top {
      .title {
        white-space: nowrap
      }

      & > div {
        width: 130px;
        flex: 0 0 130px;
      }
    }
    .down {
      width: calc(130px * 5 + 15px * 5);

      .body {
        & > div {
          width: 130px;
          flex: 0 0 130px;
        }
      }
    }
    .scroll-wrap {
      padding-left: 16px;
      padding-right: 16px;
      overflow: auto;
      @include scrollbarsInit();
    }
    &:before {
      content: "";
      display: block;
      width: 32px;
      border-radius: 8px 0 0 8px;
      position: absolute;
      left: -1px;
      top: 0;
      bottom: 0;
      background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
      z-index: 2
    }
    &:after {
      content: "";
      display: block;
      width: 32px;
      border-radius: 0 8px 8px 0;
      position: absolute;
      right: -1px;
      top: 0;
      bottom: 0;
      background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
      z-index: 2;
    }
  }
}

.table-wrap-shadow {
  position: relative;
  border-radius: 8px;
  @media (max-width: 1280px) {
    &:before {
      content: "";
      display: block;
      width: 24px;
      border-radius: 8px 0 0 8px;
      position: absolute;
      left: -1px;
      top: 0;
      bottom: 0;
      background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
      z-index: 2;
    }
    &:after {
      content: "";
      display: block;
      width: 24px;
      border-radius: 0 8px 8px 0;
      position: absolute;
      right: -1px;
      top: 0;
      bottom: 0;
      background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
      z-index: 2;
    }
  }
}

.table-wrap {
  width: 100%;
  overflow-x: auto;
  position: relative;
  @include scrollbarsInit();

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      th {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: #383C40;
        padding: 21px 12px;
        text-align: left;
        background: #E5E9ED !important;
        white-space: nowrap;

        &:first-child {
          border-radius: 8px 0 0px 0px;
        }

        &:last-child {
          border-radius: 0 8px 0px 0px;
        }
      }
    }

    tbody {
      tr {
        td {
          background: #fff;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #383C40;
          padding: 32px 12px;
          vertical-align: top;

          .material-icons-outlined {
            vertical-align: middle
          }

          a {
            color: #0F9AFF;
            text-decoration: none;
          }
        }


        &:last-child td {
          //&:first-child {border-radius: 0 0px 0px 8px;}
          //&:last-child {border-radius: 0px 0 8px 0px;}
        }
      }
    }
  }

  .table-icon {
    font-size: 20px;
    color: #D00209;
    display: inline-block;
    vertical-align: middle;
    padding: 2px 3px;
  }
}

.paging {
  border-top: 1px solid #E5E9ED;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 0 0 8px 8px;
  padding: 32px;
  margin-bottom: 40px;
  z-index: 1;
  @media (max-width: 769px) {
    flex-direction: column;
    border: 0;
    background: transparent;
    & > div:first-child {
      margin-bottom: 24px;
    }
  }
}

.pagination {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;

  li {
    width: 32px;
    height: 21px;
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #848C94;
    padding: 7px 2px;
    border-radius: 8px;
    margin: 0 1px;
    vertical-align: middle;
    cursor: pointer;
    text-align: center;

    .material-icons-outlined {
      font-size: 19px;
    }

    &.active {
      background: #E5E9ED;
      color: #18191C !important;
    }

    &:hover {
      background: #E5E9ED;
      color: #D00209;
    }
  }
}

.order-card-mobile {
  background: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 16px;

  .top {
    border-bottom: 1px solid #E5E9ED;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;

    .btn {
      padding: 2px 3px;
      vertical-align: middle;
    }

    .order-status {
      font-size: 12px;
      margin-right: 16px;
    }
  }

  .show-less {
    text-align: center;
    padding: 9px 0;
    margin: 0;
    line-height: 1;
    border-top: 1px solid #E5E9ED;

    & > div {
      display: inline-block;
      cursor: pointer;
      font-weight: 400;
      font-size: 12px;
      line-height: 1;
      color: #D00209;
      vertical-align: middle;
    }

    span, i {
      vertical-align: baseline;
      display: inline-block;
      line-height: 1;
    }

    i {
      font-size: 127%;
      vertical-align: middle;
      margin-left: 5px;
    }

    span + span {
      display: none
    }

    i + i {
      display: none
    }
  }

  .body {
    .details, .descr {
      display: flex;
      flex-wrap: wrap;

      & > div {
        flex: 0 0 50%;
        padding: 16px 16px 6px;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #383C40;

        .material-icons-outlined {
          vertical-align: middle
        }

        a {
          color: #0F9AFF;
          text-decoration: none;
        }
      }
    }

    .line {
      display: none;
      flex: 0 0 100%;
      border-top: 1px solid #E5E9ED;
      height: 1px;
      line-height: 1;
      padding: 0;
      margin-top: 6px;
    }

    .descr {
      max-height: 2000px;
      overflow: hidden;
      @include easy();
    }

    label {
      display: block;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: #848C94;
      border-bottom: 1px solid #C9CED4;
      padding: 0 0 4px;
      margin-bottom: 12px;
    }
  }

  &.open {
    .line {
      display: block
    }

    .body .descr {
      max-height: 10000px;
    }

    .show-less {
      span, i {
        display: none
      }

      span + span {
        display: inline-block
      }

      i + i {
        display: inline-block
      }
    }
  }
}

.experience-status {
  span {
    display: inline-block;
    vertical-align: top;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #18191C;
    padding: 9px 6px;
    margin-right: 4px;
    margin-bottom: 4px;
    border-radius: 2px;
    min-width: 36px;

    i {
      display: block;
      font-style: normal;
    }
  }

  @media (max-width: 769px) {
    span {
      font-size: 10px;
      line-height: 12px;
    }
  }
}

.order-status {
  padding: 8px 15px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: inline-block;
  color: #18191C;

  &-yellow {
    background: #FFCC17;
  }

  &-green {
    background: #00B145;
    color: #fff
  }

  &-red {
    background: #D00209;
    color: #fff
  }

  &-orange {
    background: #ED8000;
    color: #fff
  }

  &-blue {
    background: #0F9AFF;
    color: #fff
  }

  &-purple {
    background: #635EFF;
    color: #fff
  }
}

.filter-page-body {
  border-radius: 12px;
  padding: 0 32px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 0;
  max-height: 0;
  overflow: hidden;
  @include easy();

  .form-group {
    margin-bottom: 0px;
  }

  &.open {
    border: 1px solid #D4D9DE;
    padding: 32px 32px 12px;
    max-height: 10000px;
    margin-bottom: 32px;
  }

  & > div {
    flex: 0 0 calc((100% / 6) - 14px);
    position: relative
  }

  .date-field:after {
    content: "-";
    position: absolute;
    right: -12px;
    top: 24px;
  }

  .actions {
    padding-top: 8px;
    padding-bottom: 16px;
    white-space: nowrap;

    .btn {
      width: 201px;
      vertical-align: middle;
    }

    .btn-red {
      margin-right: 16px;
      white-space: nowrap;
    }
  }

  @media (max-width: 1310px) {
    .actions {
      padding-top: 0;
    }
  }
  @media (max-width: 992px) {
    &.open {
      padding: 20px 20px 12px;
    }
    .actions {
      padding-top: 16px;
    }
    & > div {
      flex: 0 0 calc((100% / 3) - 17px);
    }
  }
  @media (max-width: 769px) {
    gap: 0;
    &.open {
      padding: 16px 16px 20px;
      margin-bottom: 24px;
    }
    .form-group {
      margin-bottom: 12px;
    }
    .actions {
      padding: 0;
      display: flex;

      .btn {
        width: auto
      }

      .btn-red {
        width: 100%
      }
    }
    & > div {
      flex: 0 0 100%
    }
    & > .date-field, & > .date-field + div {
      flex: 0 0 calc((100% / 2) - 8px);
    }
    & > .date-field {
      margin-right: 15px;

      &:after {
        right: -12px;
      }
    }
  }
}